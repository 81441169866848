// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chile-jsx": () => import("./../../../src/pages/chile.jsx" /* webpackChunkName: "component---src-pages-chile-jsx" */),
  "component---src-pages-colombia-jsx": () => import("./../../../src/pages/colombia.jsx" /* webpackChunkName: "component---src-pages-colombia-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-venezuela-jsx": () => import("./../../../src/pages/venezuela.jsx" /* webpackChunkName: "component---src-pages-venezuela-jsx" */)
}

